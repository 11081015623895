import React from "react";
import styles from "./styles.module.scss";
import RemoveIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import {
  Switch,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import CloseableDialogTitle from "@protego/sdk/UI/CloseableDialogTitle/CloseableDialogTitle";
import IntlMessages from "@protego/sdk/UI/IntlMessages";
import { useIntl } from "react-intl";
import Select from "@protego/sdk/UI/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { fieldType } from "./fieldType";
import fieldConfig from "./field-config.json";

const FormEditor = React.forwardRef((props, ref) => {
  const { data } = props;
  const intl = useIntl();
  const [name, setName] = React.useState(data?.name);
  const [liveness, setLiveness] = React.useState(data?.liveness ? true : false);
  // const [fields, setFields] = React.useState([...data?.fields])
  const [fields, setFields] = React.useState({ ...data?.fields });
  const [fieldsSort, setFieldsSort] = React.useState([]);
  const [exchangingField, setExchangingField] = React.useState(-1);
  const [note, setNote] = React.useState(data?.note);
  const [openAddField, setOpenAddField] = React.useState(false);
  const [editField, setEditField] = React.useState();
  // const [module, setModule] = React.useState()
  // const [modules, setModules] = React.useState(['module1', 'module2'])
  const [type, setType] = React.useState();
  const [types, setTypes] = React.useState();
  const [count, setCount] = React.useState(0);
  const [formError, setFormError] = React.useState({});
  const [fieldName, setFieldName] = React.useState(null);
  const [alias, setAlias] = React.useState(null);
  const [placeholder, setPlaceholder] = React.useState(null);
  const [tooltip, setTooltip] = React.useState(null);
  const [defaultValue, setDefaultValue] = React.useState(null);
  const [remark, setRemark] = React.useState(null);
  const [cn, setCn] = React.useState(false);
  // text type
  const [textLength, setTextLength] = React.useState();
  // picklist type
  const [picklistItem, setPicklistItem] = React.useState();
  // text-area-rich type
  const [
    textAreaRichDisplayLine,
    setTextAreaRichDisplayLine
  ] = React.useState();
  // checkbox type
  const [checkboxChecked, setCheckboxChecked] = React.useState("checked");
  const [checkboxRequired, setCheckboxRequired] = React.useState(false);
  // number type
  const [numberDecimal, setNumberDecimal] = React.useState();
  // table type
  const [tableItem, setTableItem] = React.useState();

  React.useEffect(() => {
    // console.log('data', data)
    let arr = [];
    for (const key in fields) {
      if (fields[key].selected) {
        arr.push({ ...fields[key] });
      }
    }
    arr.sort((a, b) => a.index - b.index);
    // console.log('arr', arr)
    let sort = [];
    for (const item of arr) {
      sort.push(item.name);
    }
    // console.log('sort', sort)
    setFieldsSort(sort);

    let newType = fieldType();
    setType(newType[0]);
    setTypes(newType);
  }, []);

  // React.useEffect(() => {
  //   console.log('fields', fields)
  // }, [fields])

  // React.useEffect(() => {
  //   console.log('fieldsSort', fieldsSort)
  // }, [fieldsSort])

  // React.useEffect(() => {
  //   console.log('exchangingField', exchangingField)
  // }, [exchangingField])

  // React.useEffect(() => {
  //   console.log('data', data)
  // }, [data])

  React.useImperativeHandle(ref, () => {
    return {
      formData: () => {
        let tempFields = { ...fields };
        for (const key in tempFields) {
          fields[key].index = null;
        }
        for (let index = 0; index < fieldsSort.length; index++) {
          fields[fieldsSort[index]].index = index + 1;
        }
        let newFields = [];
        for (const key in tempFields) {
          newFields.push({
            ...fields[key],
            module: data?.customize ? name : data?.name
          });
        }
        return data?.customize
          ? { key: data?.key, name: name, note: note, fields: newFields }
          : { name: data?.name, note: note, fields: newFields };
      },
      liveness: () => {
        return liveness;
      }
    };
  });

  const formErrorCheck = () => {
    let pass = true;
    let error = { ...formError };
    if (!fieldName || fieldName.length == 0) {
      pass = false;
      error.fieldName = true;
    }
    if (editField) {
      if (editField != fieldName && fields[fieldName]) {
        pass = false;
        error.fieldName = true;
      }
    } else {
      if (fields[fieldName]) {
        pass = false;
        error.fieldName = true;
      }
    }
    if (
      type == "text" ||
      type == "text-area-long" ||
      type == "text-area-rich" ||
      type == "number" ||
      type == "percent"
    ) {
      if (!textLength || textLength.length == 0) {
        pass = false;
        error.textLength = true;
      }
      if (type == "text-area-rich") {
        if (!textAreaRichDisplayLine || textAreaRichDisplayLine.length == 0) {
          pass = false;
          error.textAreaRichDisplayLine = true;
        }
      } else if (type == "number" || type == "percent") {
        if (numberDecimal == undefined || numberDecimal.length == 0) {
          pass = false;
          error.numberDecimal = true;
        }
      }
    } else if (
      type == "picklist" ||
      type == "multi-select" ||
      type == "multi-checkbox"
    ) {
      if (!picklistItem || picklistItem.length == 0) {
        pass = false;
        error.picklistItem = true;
      }
    } else if (type == "table") {
      if (
        !tableItem ||
        tableItem.length == 0 ||
        tableItem.split("\n").length > 12
      ) {
        pass = false;
        error.tableItem = true;
      }
    }
    setFormError(error);
    return pass;
  };

  const clearField = () => {
    setEditField(undefined);
    setType(types[0]);
    setFormError({});
    setFieldName(undefined);
    setAlias(undefined);
    setPlaceholder(undefined);
    setTooltip(undefined);
    setDefaultValue(undefined);
    setRemark(undefined);
    setCn(false);
    setTextLength(undefined);
    setPicklistItem(undefined);
    setTextAreaRichDisplayLine(undefined);
    setCheckboxChecked("checked");
    setCheckboxRequired(false);
    setNumberDecimal(undefined);
    setTableItem(undefined);
  };

  const addField = () => {
    if (!formErrorCheck()) {
      return;
    }

    setOpenAddField(false);

    let field = {
      type,
      module: data?.name,
      name: fieldName,
      alias: alias,
      describe: placeholder,
      helpText: tooltip,
      remark: remark,
      cn: cn,
      required: false,
      selected: false,
      added: true,
      limit: { required: false }
    };
    if (
      type == "text" ||
      type == "text-area-long" ||
      type == "text-area-rich" ||
      type == "number" ||
      type == "percent"
    ) {
      field.limit.length = parseInt(textLength);
      field.limit.defaults = defaultValue;
      if (type == "text-area-rich") {
        field.limit.displayLine = parseInt(textAreaRichDisplayLine);
      } else if (type == "number" || type == "percent") {
        field.limit.decimalPoint = parseInt(numberDecimal);
      }
    } else if (
      type == "picklist" ||
      type == "multi-select" ||
      type == "multi-checkbox"
    ) {
      field.limit.item =
        picklistItem && picklistItem.length > 0 ? picklistItem.split("\n") : [];
      field.limit.defaults = defaultValue;
    } else if (type == "checkbox") {
      field.limit.required = checkboxRequired;
      field.limit.defaults = checkboxChecked == "checked";
    } else if (
      type == "email" ||
      type == "url" ||
      type == "phone" ||
      type == "updating-files"
    ) {
      field.limit = {
        defaults: defaultValue
      };
    } else if (type == "table") {
      field.limit.item =
        tableItem && tableItem.length > 0 ? tableItem.split("\n") : [];
    }
    // console.log('field', field)
    let newFields = { ...fields };
    newFields[field.name] = field;
    setFields(newFields);

    clearField();
  };

  const saveField = () => {
    if (!editField) {
      return;
    }

    if (!formErrorCheck()) {
      return;
    }

    setOpenAddField(false);

    let field = {
      type,
      module: data?.name,
      name: fieldName,
      alias: alias,
      describe: placeholder,
      helpText: tooltip,
      remark: remark,
      cn: cn,
      required: fields[editField].required,
      selected: fields[editField].selected,
      added: fields[editField].added,
      limit: fields[editField].limit
    };
    if (
      type == "text" ||
      type == "text-area-long" ||
      type == "text-area-rich" ||
      type == "number" ||
      type == "percent"
    ) {
      field.limit.length = parseInt(textLength);
      field.limit.defaults = defaultValue;
      if (type == "text-area-rich") {
        field.limit.displayLine = parseInt(textAreaRichDisplayLine);
      } else if (type == "number" || type == "percent") {
        field.limit.decimalPoint = parseInt(numberDecimal);
      }
    } else if (
      type == "picklist" ||
      type == "multi-select" ||
      type == "multi-checkbox"
    ) {
      field.limit.item =
        picklistItem && picklistItem.length > 0 ? picklistItem.split("\n") : [];
      field.limit.defaults = defaultValue;
    } else if (type == "checkbox") {
      field.limit.required = checkboxRequired;
      field.limit.defaults = checkboxChecked == "checked";
    } else if (
      type == "email" ||
      type == "url" ||
      type == "phone" ||
      type == "updating-files" ||
      type == "downloadLink"
    ) {
      field.limit = {
        defaults: defaultValue
      };
    } else if (type == "table") {
      field.limit.item =
        tableItem && tableItem.length > 0 ? tableItem.split("\n") : [];
    }
    // console.log('field', field)
    let newFields = { ...fields };
    delete newFields[editField];
    newFields[field.name] = field;
    setFields(newFields);

    if (editField != fieldName && fields[editField].selected) {
      let sort = [...fieldsSort];
      for (const i in sort) {
        if (sort[i] == editField) {
          // console.log('sort[i]', sort[i])
          sort[i] = fieldName;
          break;
        }
      }
      // console.log('sort', sort)
      setFieldsSort(sort);
    }

    clearField();
  };

  return (
    <>
      <div className={styles.formName}>
        <div className={styles.left}>
          {data?.customize ? (
            <div className={styles.name}>
              <IntlMessages id="company.businessform.title" />
              <TextField
                className={styles.input}
                fullWidth
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "company.businessform.formname"
                })}
                value={name}
                onChange={e => {
                  setName(e.target.value);
                }}
              />
            </div>
          ) : (
            <div>{data?.name}</div>
          )}
        </div>
        {data?.liveness != undefined && (
          <div>
            <IntlMessages id="company.businessform.liveness" />
            <Switch
              checked={liveness}
              onClick={() => {
                setLiveness(!liveness);
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.mainBox}>
        <div className={styles.leftBox}>
          <div className={styles.borderBox}>
            <div className={styles.fieldBox}>
              {fieldsSort.map((key, index) => {
                let item = fields[key];
                return (
                  <>
                    {item && item.selected && (
                      <div
                        className={clsx(
                          styles.field,
                          item.required
                            ? styles.invalid
                            : index == exchangingField
                            ? styles.origin
                            : exchangingField != -1 && styles.target
                        )}
                        onClick={() => {
                          setCount(count + 1);
                          setTimeout(() => {
                            setCount(lastCount => {
                              // console.log('lastCount', lastCount)
                              if (lastCount == 1) {
                                if (!item.required) {
                                  if (exchangingField == -1) {
                                    if (fieldsSort.length > 1) {
                                      setExchangingField(index);
                                    }
                                  } else {
                                    if (exchangingField != index) {
                                      let origin = fieldsSort[exchangingField];
                                      let target = key;
                                      let sort = [...fieldsSort];
                                      sort[exchangingField] = target;
                                      sort[index] = origin;
                                      setFieldsSort(sort);
                                    }
                                    setExchangingField(-1);
                                  }
                                }
                              } else if (lastCount == 2) {
                                if (!item.required) {
                                  let newFields = { ...fields };
                                  newFields[key].limit.required = !item.limit
                                    .required;
                                  setFields(newFields);
                                  setExchangingField(-1);
                                }
                              }
                              return 0;
                            });
                          }, 200);
                        }}
                      >
                        <div className={styles.text}>
                          {item.limit.required && (
                            <div className={styles.required}>*</div>
                          )}
                          {item.name}
                        </div>
                        {!item.required && !item.limit.required && (
                          <RemoveIcon
                            className={styles.delete}
                            onClick={e => {
                              e.stopPropagation();

                              let newFields = { ...fields };
                              newFields[key].selected = false;
                              setFields(newFields);

                              let sort = [...fieldsSort];
                              sort.splice(index, 1);
                              setFieldsSort(sort);

                              setExchangingField(-1);
                            }}
                          />
                        )}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
            <div className={styles.hint}>
              <IntlMessages id="company.businessform.required" />
            </div>
          </div>
        </div>
        <div className={styles.rightBox}>
          <div className={styles.fieldBox}>
            {Object.entries(fields).map(([key, item]) => {
              return (
                <>
                  <div
                    className={clsx(
                      styles.field,
                      item.selected && styles.selected
                    )}
                    onClick={() => {
                      if (!item.selected) {
                        let newFields = { ...fields };
                        newFields[key].selected = true;
                        setFields(newFields);

                        let sort = [...fieldsSort];
                        sort.push(key);
                        setFieldsSort(sort);

                        setExchangingField(-1);
                      }
                    }}
                  >
                    <div className={styles.text}>{item.name}</div>
                    <EditIcon
                      className={styles.edit}
                      onClick={e => {
                        e.stopPropagation();

                        setEditField(key);

                        // console.log('item', item)
                        setType(item.type);
                        setFieldName(item.name);
                        setAlias(item.alias);
                        setPlaceholder(item.describe);
                        setTooltip(item.helpText);
                        setRemark(item.remark);
                        setCn(item.cn);
                        let type = item.type;
                        if (
                          type == "text" ||
                          type == "text-area-long" ||
                          type == "text-area-rich" ||
                          type == "number" ||
                          type == "percent" ||
                          type == "downloadLink"
                        ) {
                          setTextLength(item.limit?.length);
                          setDefaultValue(item.limit?.defaults);
                          if (type == "text-area-rich") {
                            setTextAreaRichDisplayLine(item.limit?.displayLine);
                          } else if (type == "number" || type == "percent") {
                            setNumberDecimal(item.limit?.decimalPoint);
                          }
                        } else if (
                          type == "picklist" ||
                          type == "multi-select" ||
                          type == "multi-checkbox"
                        ) {
                          let items = "";
                          if (item.limit?.item) {
                            for (let i = 0; i < item.limit.item.length; i++) {
                              if (i > 0) {
                                items += "\n";
                              }
                              items += item.limit.item[i];
                            }
                          }
                          setPicklistItem(items);
                          setDefaultValue(item.limit?.defaults);
                        } else if (type == "checkbox") {
                          setCheckboxRequired(item.limit?.required);
                          setCheckboxChecked(
                            item.limit?.defaults ? "checked" : "unchecked"
                          );
                        } else if (
                          type == "email" ||
                          type == "url" ||
                          type == "phone" ||
                          type == "updating-files"
                        ) {
                          setDefaultValue(item.limit?.defaults);
                        } else if (type == "table") {
                          let items = "";
                          if (item.limit?.item) {
                            for (let i = 0; i < item.limit.item.length; i++) {
                              if (i > 0) {
                                items += "\n";
                              }
                              items += item.limit.item[i];
                            }
                          }
                          setTableItem(items);
                        }

                        setOpenAddField(true);
                      }}
                    />
                  </div>
                </>
              );
            })}
            <Button
              style={{ height: "40px", marginBottom: "20px" }}
              variant={"contained"}
              color={"primary"}
              onClick={() => {
                setOpenAddField(true);
              }}
              startIcon={<AddIcon />}
            >
              <IntlMessages id="company.businessform.addfield" />
            </Button>
          </div>
          <div className="d-flex align-items-baseline">
            <div className="mr-3">
              <IntlMessages id="company.businessform.note" />
            </div>
            <TextField
              formik
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              // name={"note"}
              placeholder={intl.formatMessage({
                id: "company.businessform.note"
              })}
              value={note}
              onChange={e => setNote(e.target.value)}
            />
          </div>
        </div>
      </div>

      {openAddField && (
        <Dialog open={openAddField} fullWidth>
          <CloseableDialogTitle
            onClose={() => {
              setOpenAddField(false);
              clearField();
            }}
          >
            {editField ? (
              <>
                <IntlMessages id="company.businessform.eidtfield" /> -{" "}
                {fields[editField].name}
              </>
            ) : (
              <IntlMessages id="company.businessform.addfield" />
            )}
          </CloseableDialogTitle>
          <DialogContent>
            <div className={styles.addField}>
              <div className={styles.field}>
                <div className={styles.name}>
                  <span className={styles.required}>*</span>
                  <IntlMessages id="company.businessform.addfield.module" />
                </div>
                {/* <Select
                  fullWidth
                  placeholder={intl.formatMessage({
                    id: "company.businessform.addfield.module"
                  })}
                  value={module}
                  onChange={e => {
                    setModule(e.target.value)
                  }}
                >
                  {modules?.map(item => {
                    return (
                      <MenuItem value={item}>
                        {item}
                      </MenuItem>
                    )
                  })}
                </Select> */}
                <TextField
                  className={styles.readOnly}
                  readOnly={true}
                  fullWidth
                  variant="outlined"
                  value={data?.name}
                  placeholder={intl.formatMessage({
                    id: "company.businessform.addfield.module"
                  })}
                />
              </div>

              <div className={styles.field}>
                <div className={styles.name}>
                  <span className={styles.required}>*</span>
                  <IntlMessages id="company.businessform.addfield.type" />
                </div>
                <Select
                  className={
                    editField && !fields[editField].added && styles.readOnly
                  }
                  readOnly={editField && !fields[editField].added}
                  fullWidth
                  label={
                    <>
                      <IntlMessages id="company.businessform.addfield.type" />
                      <span className={styles.required}> *</span>
                    </>
                  }
                  value={type}
                  onChange={e => {
                    setType(e.target.value);
                    setFormError({});
                  }}
                >
                  {types?.map(item => {
                    return (
                      <MenuItem value={item}>
                        {fieldConfig[item] ? fieldConfig[item].name : item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>

              {fieldConfig[type]?.info && (
                <div className={styles.info}>{fieldConfig[type].info}</div>
              )}
              {fieldConfig[type]?.link && (
                <div className={styles.info}>
                  <IntlMessages id="company.businessform.addfield.link1" />
                  <a
                    style={{ color: "#00bcd4" }}
                    href={fieldConfig[type].link}
                    target="_blank"
                  >
                    {fieldConfig[type].link}
                  </a>
                  <IntlMessages id="company.businessform.addfield.link2" />
                </div>
              )}

              <div className={styles.field}>
                <div className={styles.name}>
                  <span className={styles.required}>*</span>
                  <IntlMessages id="company.businessform.addfield.name" />
                </div>
                <TextField
                  className={
                    editField && !fields[editField].added && styles.readOnly
                  }
                  readOnly={editField && !fields[editField].added}
                  fullWidth
                  required
                  variant="outlined"
                  placeholder={intl.formatMessage({
                    id: "company.businessform.addfield.name"
                  })}
                  value={fieldName}
                  onChange={e => {
                    if (editField && !fields[editField].added) {
                      return;
                    }
                    setFieldName(e.target.value);
                    if (e.target.value.length > 0) {
                      setFormError({ ...formError, fieldName: false });
                    }
                  }}
                  {...(formError.fieldName && {
                    error: true,
                    helperText: intl.formatMessage({
                      id:
                        !fieldName || fieldName.length == 0
                          ? "company.businessform.addfield.name.required"
                          : "company.businessform.addfield.name.exist"
                    })
                  })}
                />
              </div>

              <div className={styles.field}>
                <div className={styles.name}>
                  <IntlMessages id="company.businessform.addfield.alias" />
                </div>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  placeholder={intl.formatMessage({
                    id: "company.businessform.addfield.alias"
                  })}
                  value={alias}
                  onChange={e => {
                    setAlias(e.target.value);
                  }}
                />
              </div>

              {(type == "text" ||
                type == "text-area-long" ||
                type == "text-area-rich" ||
                type == "number" ||
                type == "percent") && (
                <div className={styles.field}>
                  <div className={styles.name}>
                    <span className={styles.required}>*</span>
                    <IntlMessages id="company.businessform.addfield.text.length" />
                  </div>
                  <TextField
                    className={
                      editField && !fields[editField].added && styles.readOnly
                    }
                    readOnly={editField && !fields[editField].added}
                    fullWidth
                    required
                    variant="outlined"
                    type="number"
                    placeholder={
                      fieldConfig[type]?.length
                        ? fieldConfig[type]?.length
                        : "255"
                    }
                    value={textLength}
                    onChange={e => {
                      if (editField && !fields[editField].added) {
                        return;
                      }
                      setTextLength(e.target.value);
                      if (e.target.value.length > 0) {
                        setFormError({ ...formError, textLength: false });
                      }
                    }}
                    {...(formError.textLength && {
                      error: true,
                      helperText:
                        intl.formatMessage({
                          id: "company.businessform.addfield.text.length"
                        }) +
                        intl.formatMessage({
                          id: "company.businessform.addfield.required"
                        })
                    })}
                  />
                </div>
              )}

              {(type == "picklist" ||
                type == "multi-select" ||
                type == "multi-checkbox") && (
                <div className={styles.field}>
                  <div className={styles.name}>
                    <span className={styles.required}>*</span>
                    <IntlMessages id="company.businessform.addfield.picklist.item" />
                  </div>
                  <TextField
                    className={
                      editField && !fields[editField].added && styles.readOnly
                    }
                    readOnly={editField && !fields[editField].added}
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id:
                        "company.businessform.addfield.picklist.item.placeholder"
                    })}
                    value={picklistItem}
                    onChange={e => {
                      if (editField && !fields[editField].added) {
                        return;
                      }
                      setPicklistItem(e.target.value);
                      if (e.target.value.length > 0) {
                        setFormError({ ...formError, picklistItem: false });
                      }
                    }}
                    {...(formError.picklistItem && {
                      error: true,
                      helperText:
                        intl.formatMessage({
                          id: "company.businessform.addfield.picklist.item"
                        }) +
                        intl.formatMessage({
                          id: "company.businessform.addfield.required"
                        })
                    })}
                  />
                </div>
              )}

              {type == "table" && (
                <div className={styles.field}>
                  <div className={styles.name}>
                    <span className={styles.required}>*</span>
                    <IntlMessages id="company.businessform.addfield.table.item" />
                  </div>
                  <TextField
                    className={
                      editField && !fields[editField].added && styles.readOnly
                    }
                    readOnly={editField && !fields[editField].added}
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "company.businessform.addfield.table.item.placeholder"
                    })}
                    value={tableItem}
                    onChange={e => {
                      if (editField && !fields[editField].added) {
                        return;
                      }
                      setTableItem(e.target.value);
                      if (e.target.value.length > 0) {
                        setFormError({ ...formError, tableItem: false });
                      }
                    }}
                    {...(formError.tableItem && {
                      error: true,
                      helperText:
                        tableItem.split("\n").length > 12
                          ? intl.formatMessage({
                              id: "company.businessform.addfield.table.error"
                            })
                          : intl.formatMessage({
                              id: "company.businessform.addfield.table.item"
                            }) +
                            intl.formatMessage({
                              id: "company.businessform.addfield.required"
                            })
                    })}
                  />
                </div>
              )}

              {type == "text-area-rich" && (
                <div className={styles.field}>
                  <div className={styles.name}>
                    <span className={styles.required}>*</span>
                    <IntlMessages id="company.businessform.addfield.textarearich.line" />
                  </div>
                  <TextField
                    className={
                      editField && !fields[editField].added && styles.readOnly
                    }
                    readOnly={editField && !fields[editField].added}
                    fullWidth
                    required
                    variant="outlined"
                    type="number"
                    placeholder={"12"}
                    value={textAreaRichDisplayLine}
                    onChange={e => {
                      if (editField && !fields[editField].added) {
                        return;
                      }
                      setTextAreaRichDisplayLine(e.target.value);
                      if (e.target.value.length > 0) {
                        setFormError({
                          ...formError,
                          textAreaRichDisplayLine: false
                        });
                      }
                    }}
                    {...(formError.textAreaRichDisplayLine && {
                      error: true,
                      helperText:
                        intl.formatMessage({
                          id: "company.businessform.addfield.textarearich.line"
                        }) +
                        intl.formatMessage({
                          id: "company.businessform.addfield.required"
                        })
                    })}
                  />
                </div>
              )}

              {(type == "number" || type == "percent") && (
                <div className={styles.field}>
                  <div className={styles.name}>
                    <span className={styles.required}>*</span>
                    <IntlMessages id="company.businessform.addfield.number.decimal" />
                  </div>
                  <TextField
                    className={
                      editField && !fields[editField].added && styles.readOnly
                    }
                    readOnly={editField && !fields[editField].added}
                    fullWidth
                    required
                    variant="outlined"
                    type="number"
                    placeholder={"2"}
                    value={numberDecimal}
                    onChange={e => {
                      if (editField && !fields[editField].added) {
                        return;
                      }
                      setNumberDecimal(e.target.value);
                      if (e.target.value.length > 0) {
                        setFormError({ ...formError, numberDecimal: false });
                      }
                    }}
                    {...(formError.numberDecimal && {
                      error: true,
                      helperText:
                        intl.formatMessage({
                          id: "company.businessform.addfield.number.decimal"
                        }) +
                        intl.formatMessage({
                          id: "company.businessform.addfield.required"
                        })
                    })}
                  />
                </div>
              )}

              {type == "checkbox" && (
                <div className={styles.field}>
                  <div className={styles.name}>
                    <IntlMessages id="company.businessform.addfield.checkbox.default" />
                  </div>
                  <RadioGroup
                    row
                    value={checkboxChecked}
                    onChange={e => {
                      setCheckboxChecked(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={"checked"}
                      control={<Radio color="default" />}
                      label={intl.formatMessage({
                        id: "company.businessform.addfield.checkbox.checked"
                      })}
                    />
                    <FormControlLabel
                      value={"unchecked"}
                      control={<Radio color="default" />}
                      label={intl.formatMessage({
                        id: "company.businessform.addfield.checkbox.unchecked"
                      })}
                    />
                  </RadioGroup>
                </div>
              )}

              {((type != "table" && type != "header") ||
                type != "downloadLink") && (
                <div className={styles.field}>
                  <div className={styles.name}>
                    <IntlMessages id="company.businessform.addfield.placeholder" />
                  </div>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "company.businessform.addfield.placeholder"
                    })}
                    value={placeholder}
                    onChange={e => {
                      setPlaceholder(e.target.value);
                    }}
                  />
                </div>
              )}

              <div className={styles.field}>
                <div className={styles.name}>
                  <IntlMessages id="company.businessform.addfield.tooltip" />
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder={intl.formatMessage({
                    id: "company.businessform.addfield.tooltip"
                  })}
                  value={tooltip}
                  onChange={e => {
                    setTooltip(e.target.value);
                  }}
                />
              </div>

              {type != "checkbox" &&
                type != "currency" &&
                type != "picklist-nature-of-business" &&
                type != "picklist-country" &&
                type != "picklist-company-type" &&
                type != "picklist-company-size" &&
                type != "picklist-industry" &&
                type != "picklist-occupation" &&
                type != "multi-select-country" &&
                type != "multi-select-currency" &&
                type != "table" &&
                type != "header" && (
                  <div className={styles.field}>
                    <div className={styles.name}>
                      <IntlMessages id="company.businessform.addfield.default" />
                    </div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={intl.formatMessage({
                        id: "company.businessform.addfield.default"
                      })}
                      value={defaultValue}
                      onChange={e => {
                        setDefaultValue(e.target.value);
                      }}
                    />
                  </div>
                )}

              <div className={styles.field}>
                <div className={styles.name}>
                  <IntlMessages id="company.businessform.addfield.checkbox.remark" />
                </div>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  placeholder={intl.formatMessage({
                    id: "company.businessform.addfield.checkbox.remark"
                  })}
                  value={remark}
                  onChange={e => {
                    setRemark(e.target.value);
                  }}
                />
              </div>

              {type == "checkbox" && (
                <div className={styles.field}>
                  <div className={styles.name}></div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkboxRequired}
                        onChange={(event, checked) => {
                          setCheckboxRequired(checked);
                        }}
                      />
                    }
                    label={intl.formatMessage({
                      id: "company.businessform.addfield.checkbox.required"
                    })}
                  />
                </div>
              )}

              <div className={styles.field}>
                <div className={styles.name}></div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cn}
                      onChange={(event, checked) => {
                        setCn(checked);
                      }}
                    />
                  }
                  label={intl.formatMessage({
                    id: "company.businessform.addfield.checkbox.cn"
                  })}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="mb-4 mr-4">
              <Button
                color="primary"
                variant="contained"
                className="mr-4"
                onClick={() => {
                  if (editField) {
                    saveField();
                  } else {
                    addField();
                  }
                }}
              >
                <IntlMessages id="company.dialog.detail.save" />
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenAddField(false);
                  clearField();
                }}
              >
                <IntlMessages id="button.cancel" />
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
});

export default FormEditor;
